import React, { Component } from "react";
import Slider from "react-slick";
import '../styles/Silder.scss';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      pauseOnHover:false,
      autoplaySpeed: 7000,
    }
    return (
      <div>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <Slider {...settings}>
          <div className="slideFirst">
            <div className="slideFirst_image"></div>
          <div className="sliderMainDescription">
          <h1 className="slideFirst_big">Willkommen auf der Homepage<br/>des Vereins EPKT-Dubasării-Vechi</h1>
          <h6 className="slideFirst_small">Erhaltung und Pflege der Kulturdenkmale und Tradition<br/>in Dubăsarii- Vechi Moldawien</h6>
          </div>

          </div>
          <div className="slideSecond">
          <div className="slideSecond_image"></div>
          <div className="sliderMainDescription">
            <h1 className="slideSecond_big">Entdecken Sie<br/>das Land Moldawien</h1>
            <h6 className="slideSecond_small">Entdecken Sie die berühmtesten<br/>Orte in Moldawien</h6>
          </div>
          </div>

          <div className="slideThird">
          <div className="slideThird_image"></div>
          <div className="sliderMainDescription">
            <h1 className="slideThird_big">Entdecken Sie das Dorf<br/>Dubăsarii Vechi</h1>
            <h6 className="slideThird_small">Entdecken Sie das Dorf Dubăsarii Vechi am Ufer des Flusses Nistru<br/>Ein schöner kleiner Teil von Moldawien</h6>
          </div>
          </div>
        </Slider>
      </div>
    );
  }
}