import React from 'react';
import '../styles/Home.scss';
import face from '../img/face.png';
import wiki from '../img/wiki.png';
import { Link } from 'react-router-dom';

function Footer() {
  return ( <div>
            <div className='footer'>
                <div className='footer_adresse'>
                    <h2>Adresse</h2>
                    <p>Paracelsusstraße 83</p>
                    <p>8370771 Leinfelden-Echterdingen</p>
                </div>
                <div className='footer_kontakt'>
                    <h2>Kontakt</h2>
                    <p>epkt.verein@gmail.com</p>
                    <p>+49 711 342 18 542</p>
                    <p>+49 151 583 67 005</p>
                </div>
                <div className='footer_impressum'>
                    <h2>Impressum</h2>
                    <Link to='/datenschutz'>Datenschutzerklärung</Link>
                    <Link to='/impressum'>Impressum</Link>
                    <a target="_blank" href='https://drive.google.com/file/d/1urCcGecuBpIFBIYJD2SjhxrhmG60-osD/preview'>Satzung</a>
                </div>
                <div className='footer_social'>
                    <h2>Social media</h2>
                    <div className='footer_social_icons'>
                        <div className='footer_social_icons_face'>
                            <a target="_blank" href='https://www.facebook.com/profile.php?id=100004111579476'> 
                                <img src={face}/>
                            </a>
                        </div>
                        <div className='footer_social_icons_wiki'>
                            <a target="_blank" href='https://de.wikipedia.org/wiki/Republik_Moldau'>
                            <img src={wiki}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
}

export default Footer;

