import React from 'react';
import Home from './components/Home';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/impressum' element={<Impressum/>} />
          <Route path='/datenschutz' element={<Datenschutz/>} />
           
        </Routes>
      </Router>
    </div>
  );
}

export default App;
