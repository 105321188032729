import React from 'react';
import '../styles/Menu.scss';
import burger from '../img/menu.png';
import { Link,  animateScroll as scroll } from 'react-scroll'

function Menu() {
    return (
        <div>
            <div className='Menu'>
                <div className='Menu_mobile'>
                    <img src={burger}/>
                </div>
                <div className='Menu_up'>
                    <div className='Menu_up_inside'>
                        <h3 className='Menu_up_inside_text'>EPKT</h3>
                    </div>
                </div>

                <div className='Menu_below'>
                        <ul className='Menu_below_list'>
                            <li className='Menu_below_list_child'>
                            <Link activeClass="active"
                                  to="main"
                                  spy={true}
                                  smooth={true}
                                  hashSpy={true}
                                  offset={0}
                                  duration={500}
                                  /* delay={500} */>
                                  EPKT              
                            </Link>
                            </li>

                            <li className='Menu_below_list_child'>
                            <Link activeClass="active"
                                  to="moldawien"
                                  spy={true}
                                  smooth={true}
                                  hashSpy={true}
                                  offset={0}
                                  duration={500}
                                  /* delay={500} */>
                                  Republik Moldau              
                            </Link>   
                            </li>

                            <li className='Menu_below_list_child'>
                            <Link activeClass="active"
                                  to="dubasari"
                                  spy={true}
                                  smooth={true}
                                  hashSpy={true}
                                  offset={0}
                                  duration={500}
                                  /* delay={500} */>
                                  Dubăsarii Vechi             
                            </Link>  
                            </li>

                            <li className='Menu_below_list_child'>
                            <Link activeClass="active"
                                  to="video"
                                  spy={true}
                                  smooth={true}
                                  hashSpy={true}
                                  offset={0}
                                  duration={500}
                                  /* delay={500} */>
                                  Panorama             
                            </Link> 
                            </li>

                            <li className='Menu_below_list_child'>
                            <Link activeClass="active"
                                  to="project"
                                  spy={true}
                                  smooth={true}
                                  hashSpy={true}
                                  offset={0}
                                  duration={500}
                                  /* delay={500} */>
                                  Unser Projekt            
                            </Link>
                            </li>
                            <li className='Menu_below_list_child'>
                            <Link activeClass="active"
                                  to="kontakt"
                                  spy={true}
                                  smooth={true}
                                  hashSpy={true}
                                  offset={0}
                                  duration={500}
                                  /* delay={500} */>
                                  Kontakt           
                            </Link>
                                
                            </li>
                        </ul>
                </div>
            </div>
        </div>
    )
}

export default Menu;
