import React, { Component } from "react";
import Slider from "react-slick";
import fotoOne from '../img/Capriana.jpg';
import fotoTwo from '../img/Chisinau.jpg';
import fotoThree from '../img/Cricova.jpg';
import fotoFour from '../img/Hancu.jpg';
import fotoFive from '../img/Kathedral.jpg';
import fotoSix from '../img/ManucBei.jpg';
import fotoSeven from '../img/MilestiiMici.jpg';
import fotoEight from '../img/Mimi.jpg';
import fotoNine from '../img/Orhei.png';
import fotoTen from '../img/Saharna.jpg';
import fotoEleven from '../img/Soroca.jpg';
import fotoTwelve from '../img/Tipova.jpg';

export default class FeaturedSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            arrows: false,
            dots:true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots:true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 380,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false
          }
        }
        
      ]
      
    };
    return (
      <div>
          <div className="upSliderSecond">
                <span className="upSliderSecond_first">Entdecke<br/><span className="upSliderSecond_second">Moldavien</span></span>
                <span className="upSliderSecond_third">
                    Entdecken Sie die berühmtesten Orte in Moldawien.<br/> 
                    Machen Sie einen virtuellen Rundgang durch die beliebtesten Orte
                </span>
            </div>
            <style>{cssstyle}</style>
            {/* <div className="mainSlider"> */}
        <Slider {...settings}>
            
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoOne}/>
                <div className="sliderSecond_image_desc">
                    <a target="_blank" href="https://de.wikipedia.org/wiki/Kloster_C%C4%83priana"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Kloster Căpriana</h2>
                <p>Das Kloster Căpriana ist ein Kloster in Căpriana, 
                   Rajon Strășeni, Republik Moldau. Es befindet sich 
                   etwa 40 km nordwestlich von Chișinău. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoTwo}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Chi%C8%99in%C4%83u"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Chișinău</h2>
                <p>Chișinău ist die Hauptstadt der Republik 
                   Moldau und mit mehr als 530.000 Einwohnern 
                   auch deren bevölkerungsreichste Stadt. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoThree}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Cricova"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Cricova</h2>
                <p>Cricova ist eine Kleinstadt in der Republik Moldau 
                   etwa 15 km nördlich von Chișinău und hat eine 
                   Bevölkerung von 14.500 Einwohnern. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoFour}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Kloster_H%C3%AEncu"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Kloster Hâncu</h2>
                <p>Das Kloster Hâncu steht in der Republik Moldau 
                   im Rajon Nisporeni, bei der Ortschaft Bursuc.
                   Das Kloster ist ein Nationaldenkmal.</p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoFive}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Kathedrale_der_Geburt_des_Herrn_(Chi%C8%99in%C4%83u)"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Kathedrale der Geburt des Herrn</h2>
                <p>Die Kathedrale der Geburt des Herrn 
                   ist der Metropolitansitz der 
                   Moldauisch-Orthodoxen Kirche. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoSix}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Schloss_von_Manuc_Bey"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Schloss von Manuc Bey</h2>
                <p>Das Schloss von Manuc Bey
                   ist ein neuzeitlicher Palast und steht in 
                    der Stadt Hâncești im zentral-westlichen Teil der Republik Moldau.
                </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoSeven}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Mile%C8%99tii_Mici"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Mileștii Mici</h2>
                <p>Die Raritätensammlung der Staatsdomäne Mileștii Mici
                   ist die größte in der Republik Moldau und wurde im Jahr 
                   2005 vom Guinness-Buch der Rekorde als größte Europas klassifiziert. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoEight}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Schloss_Mimi"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Schloss Mimi</h2>
                <p>Das Schloss Mimi ist ein neuzeitliches Schloss und Architekturdenkmal
                   von nationaler Bedeutung, das am Ende des 19. Jahrhunderts erbaut wurde 
                   und in der Ortschaft Bulboaca im Kreis Anenii Noi in der Republik Moldau steht. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoNine}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Orheiul_Vechi"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Orheiul Vechi</h2>
                <p>Orheiul Vechi ist ein archäologischer Fundort, ein historisches 
                   Siedlungsgebiet, ein kulturelles und ein landschaftliches Schutzgebiet 
                   südöstlich der Stadt Orhei im Zentrum der Republik Moldau.</p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoTen}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Kloster_Saharna"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Kloster Saharna</h2>
                <p>Das Kloster Saharna ist ein 1776 gegründetes Kloster 
                   der Moldauisch-Orthodoxen Kirche im Rajon Rezina im 
                   Nordosten der Republik Moldau.</p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoEleven}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Soroca"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Festung Soroca</h2>
                <p>Soroca ist eine Stadt im Nordosten der Republik 
                   Moldau am rechten Ufer des Dnister an der Grenze zur Ukraine. </p>
            </div>
          </div>
          <div className="sliderSecond">
            <div className="sliderSecond_image">
                <img src={fotoTwelve}/>
                <div className="sliderSecond_image_desc">
                <a target="_blank" href="https://de.wikipedia.org/wiki/Kloster_%C8%9Aipova"><span>Weiter lesen...</span></a>
                </div>
            </div>
            <div className="sliderSecond_description">
                <h2>Kloster Țipova</h2>
                <p>Das Kloster Țipova auch Uspenski-Kloster genannt, 
                   ist ein im 18. Jahrhundert neu gegründetes Kloster 
                   der Moldauisch-Orthodoxen Kirche im Rajon Rezina im Nordosten der Republik Moldau. </p>
            </div>
            </div>
          
          
        </Slider>
        </div>
      /* </div> */
    );
  }
}
const cssstyle=`
.slick-next {
    position:absolut;
    z-index:1;
    top:-10%;
    right:10%;
}
.slick-prev {
    position:absolut;
    z-index:1;
    top:-10%;
    left: 80%;
}
.slick-next:before, .slick-prev:before {
    content:"";
    font-size: 0;
}
/* .slick-slide {
  margin-left: 5px;
  border: 5px solid red;
}
.slick-track {
border: 5px solid red;
height: 400px;
}  */
/* .slick-dots li button {
 background-color: green;
  margin-bottom: 15px;
} */
`

