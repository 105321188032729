import React from 'react';
import '../styles/DatenImpressum.scss';

function Datenschutz() {
  return <div>
              <div className='datenschutz'>
                <p>
                  <span className='bigforimpressumdaten'>DATENSCHUTZERKLÄRUNG</span><br/><br/>
                  Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der 
                  Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb 
                  unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und 
                  Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf 
                  (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die 
                  verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ 
                  verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).<br/><br/>
                  
                  <span className='bigforimpressumdaten'>Verantwortlicher</span><br/><br/>
                  EPKT-Dubasarii-Vechi<br/>
                  Paracelsusstraße 83<br/>
                  70771 Leinfelden-Echterdingen<br/><br/>

                  <span className='bigforimpressumdaten'>Kontakt</span><br/><br/>
                  epkt.verein@gmail.com<br/>
                  +49 711 342 18 542<br/>
                  +49 151 583 67 005<br/><br/>

                  <span className='middleimpressumdaten'>Arten der verarbeiteten Daten:</span><br/>
                  - Bestandsdaten (z.B., Namen, Adressen)<br/>
                  - Kontaktdaten (z.B., E-Mail, Telefonnummern)<br/>
                  - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos)<br/>
                  - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten)<br/>
                  - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen)<br/><br/>

                  <span className='middleimpressumdaten'>Zweck der Verarbeitung</span><br/>
                  - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte<br/>
                  - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern<br/>
                  - Umfragen<br/>
                  - Sicherheitsmaßnahmen<br/>
                  - Reichweitenmessung/Marketing<br/><br/>

                  <span className='middleimpressumdaten'>Verwendete Begrifflichkeiten</span><br/>
                  „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte 
                  oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; 
                  als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, 
                  insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, 
                  zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren 
                  besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, 
                  physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder 
                  sozialen Identität dieser natürlichen Person sind.<br/><br/>

                  „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte 
                  Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. 
                  Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.<br/><br/>

                  „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass 
                  die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr 
                  einer spezifischen betroffenen Person zugeordnet werden können, sofern diese 
                  zusätzlichen Informationen gesondert aufbewahrt werden und technischen und 
                  organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen 
                  Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen 
                  werden.<br/><br/>

                  „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, 
                  die darin besteht, dass diese personenbezogenen Daten verwendet werden, 
                  um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, 
                  zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche 
                  Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, 
                  Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder 
                  vorherzusagen.<br/><br/>

                  Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, 
                  Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über 
                  die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, 
                  bezeichnet.<br/><br/>

                  „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, 
                  Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des 
                  Verantwortlichen verarbeitet<br/><br/>

                  <span className='middleimpressumdaten'>Maßgebliche Rechtsgrundlagen</span><br/>
                  Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer 
                  Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der Datenschutzerklärung 
                  nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung von 
                  Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage 
                  für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung 
                  vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. 
                  b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen 
                  Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die 
                  Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f 
                  DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder 
                  einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten 
                  erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.<br/><br/>

                  <span className='middleimpressumdaten'>Sicherheit</span><br/>
                  Wir treffen technische und organisatorische Sicherheitsmaßnahmen, um Ihre 
                  personenbezogenen Daten gegen zufällige oder vorsätzliche Manipulationen, 
                  Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen. 
                  Diese Sicherheitsmaßnahmen werden jeweils unter Berücksichtigung des aktuellen 
                  Standes der Technik angepasst. Ihre im Rahmen der Nutzung unserer Website 
                  übertragenen personenbezogenen Daten werden bei uns sicher durch Verschlüsselung 
                  übertragen. Wir bedienen uns dabei des Verschlüsselungsprotokolls Transport 
                  Layer Security (TLS), weitläufiger bekannt unter der Vorgängerbezeichnung Secure 
                  Sockets Layer (SSL)<br/><br/>

                  <span className='middleimpressumdaten'>Zusammenarbeit mit Auftragsverarbeitern und Dritten</span><br/>
                  Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und 
                  Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln 
                  oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer 
                  gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an 
                  Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung 
                  erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies 
                  vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz 
                  von Beauftragten, Webhostern, etc.). Sofern wir Dritte mit der Verarbeitung von 
                  Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, 
                  geschieht dies auf Grundlage des Art. 28 DSGVO.<br/><br/>

                  <span className='middleimpressumdaten'>Übermittlungen in Drittländer</span><br/>
                  Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) 
                  oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der 
                  Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von 
                  Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer 
                  (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer 
                  rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen 
                  geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten 
                  oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen 
                  Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt 
                  z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung 
                  eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das 
                  „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher 
                  Verpflichtungen (so genannte „Standardvertragsklauseln“).<br/><br/>

                  <span className='middleimpressumdaten'>Rechte der betroffenen Personen</span><br/>
                  Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten 
                  verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen 
                  und Kopie der Daten entsprechend Art. 15 DSGVO.<br/>
                  Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie 
                  betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu 
                  verlangen.<br/>
                  Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende 
                  Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO 
                  eine Einschränkung der Verarbeitung der Daten zu verlangen.<br/>
                  Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns 
                  bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung 
                  an andere Verantwortliche zu fordern.<br/>
                  Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen 
                  Aufsichtsbehörde einzureichen.<br/><br/>

                  <span className='middleimpressumdaten'>Widerspruchsrecht</span><br/>
                  Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des 
                  Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die 
                  Verarbeitung für Zwecke der Direktwerbung erfolgen.<br/><br/>

                  <span className='middleimpressumdaten'>Kontaktaufnahme</span><br/>
                  Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder 
                  via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der 
                  Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen 
                  vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f. (andere Anfragen) 
                  DSGVO verarbeitet.. Die Angaben der Nutzer können in einem Customer-Relationship-
                  Management System ("CRM System") oder vergleichbarer Anfragenorganisation gespeichert 
                  werden. Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir 
                  überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen 
                  Archivierungspflichten.<br/><br/>

                  <span className='middleimpressumdaten'>Einbindung von Diensten und Inhalten Dritter</span><br/>
                  Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten 
                  Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb 
                  unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder 
                  Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. 
                  Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”). 
                  Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der 
                  Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser 
                  senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. 
                  Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse 
                  lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so genannte 
                  Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische 
                  oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der 
                  Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen 
                  Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden 
                  und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende 
                  Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes 
                  enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.

                  <span className='middleimpressumdaten'>Youtube</span><br/>
                  Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 
                  Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: 
                  https://www.google.com/policies/privacy/, Opt-Out: 
                  https://adssettings.google.com/authenticated.<br/>
                   </p>
              </div>
         </div>;
}

export default Datenschutz;
