import React from 'react';
import Fancybox from './Fancybox';
import '../styles/Templates.scss';
import fotoOne from '../img/dub1.jpg';
import fotoTwo from '../img/dub2.jpg';
import fotoThree from '../img/dub3.jpg';
import fotoFour from '../img/dub4.jpg';
import fotoFive from '../img/dub5.jpg';
import fotoSix from '../img/dub6.jpg';
import fotoSeven from '../img/dub7.jpg';
import fotoEight from '../img/dub8.JPG';
import fotoNine from '../img/dub9.jpg';
import fotoTen from '../img/dub10.jpg';
import fotoEleven from '../img/dub11.jpg';

function Galery() {
    return (
        <div>
            <div className="upSliderSecond">
                <span className="upSliderSecond_first">Entdecke<br/><span className="upSliderSecond_second">Dubăsarii Vechi</span></span>
                <span className="upSliderSecond_third">
                    Entdecken Sie das Dorf Dubăsarii Vechi am Ufer des Flusses Nistru.<br/> 
                    Ein schöner kleiner Teil von Moldawien
                </span>
            </div>
            <Fancybox>
                <div className='galery'>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoOne}>
                            <img src={fotoOne}/>
                        </a>
                    </div>    
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoTwo}>
                            <img src={fotoTwo}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoThree}>
                            <img src={fotoThree}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoFour}>
                            <img src={fotoFour}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoFive}>
                            <img src={fotoFive}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoSix}>
                            <img src={fotoSix}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoSeven}>
                            <img src={fotoSeven}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoEight}>
                            <img src={fotoEight}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoNine}>
                            <img src={fotoNine}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoTen}>
                            <img src={fotoTen}/>
                        </a>
                    </div>
                    <div className='galery_card'>
                        <a data-fancybox='gallery' href={fotoEleven}>
                            <img src={fotoEleven}/>
                        </a>
                    </div>
                </div>
            </Fancybox>
        </div>
    )
}

export default Galery
