import React from 'react'
import SimpleSlider from '../templates/Slider';
import Menu from '../templates/Menu';
import FeaturedSlider from '../templates/Featured';
import '../styles/Home.scss';
import '../styles/Templates.scss'
import Galery from '../templates/Galery';
import Projekt from '../templates/Projekt';
import Footer from '../templates/Footer';




function Home() {
    return (
        <div>
            <section id='main'>
                <SimpleSlider/>
            </section>
                <Menu/>
            <section className='distanceContent'>
            <section id='moldawien'>    
                <FeaturedSlider/>
            </section>
                <div className='line'></div>   
            <section id='dubasari'>    
                <Galery/>
            </section>
                <div className='line'></div> 
            <section id='video'>
                <div className='videoYoutube'>
                <h3 className='videoYoutube_big'>Dubăsarii Vechi</h3>
                <p className='videoYoutube_small'>von oben</p>
                <iframe src="https://www.youtube.com/embed/NevsJDndWJo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </section>
                <div className='line'></div>
            <section id='project'>     
                <Projekt/>
            </section>
            <div className='line'></div> 
            <section id='kontakt'>
            <Footer/>
            </section>
            </section>
            
        </div>
    )
}

export default Home;
