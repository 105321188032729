import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../styles/Project.scss';
import projectOne from '../img/project/pr1.jpg';
import projectTwo from '../img/project/pr2.jpg';
import projectThree from '../img/project/pr3.jpg';
import fotoGalleryOne from '../img/project/gal1.jpg';
import fotoGalleryTwo from '../img/project/gal2.jpg';
import fotoGalleryThree from '../img/project/gal3.jpg';
import fotoGalleryFour from '../img/project/gal4.jpg';
import fotoGalleryFive from '../img/project/gal5.jpg';
import fotoGallerySix from '../img/project/gal6.jpg';
import fotoGallerySeven from '../img/project/gal7.jpg';
import fotoGalleryEight from '../img/project/gal8.jpg';
import fotoGalleryNine from '../img/project/gal9.jpg';
import fotoGalleryTen from '../img/project/gal10.jpg';
import fotoGalleryEleven from '../img/project/gal11.jpg';
import fotoGalleryTwelve from '../img/project/gal12.jpg';
import fotoGalleryThirteen from '../img/project/gal13.jpg';
import fotoGalleryFourteen from '../img/project/gal14.jpg';
import fotoGalleryFifteen from '../img/project/gal15.jpg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className="upSliderSecond">
                <span className="upSliderSecond_first">Unser<br/><span className="upSliderSecond_second">Projekt</span></span>
                <span className="upSliderSecond_third">
                Wir möchten Ihnen kurz den Zweck unseres Projekts,<br/> 
                die Kirche und das Leben in der Gemeinde vorstellen
                </span>
            </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Spendeaufruf" {...a11yProps(0)} />
          <Tab label="Die Kirche in Dubăsarii Vechi" {...a11yProps(1)} />
          <Tab label="Das Leben in der Gemeinde" {...a11yProps(2)} />
        </Tabs>
      </Box>
    
      <div className='projektTabOne'>
      <TabPanel value={value} index={0}>
        
            <p>{/* Willkommen auf der Homepage des Vereins zur Erhaltung und Pflege der 
               Kulturdenkmale und Tradition in Dubasarii- Vechi Moldawien, oder kurz: EPKT-Dubasarii-Vechi.<br/><br/> */}
               Wir bitten um Ihre Unterstützung. Wir bitten um eine Spende für die Sanierung der orthodoxen Kirche von
               Dubasarii Vecchi, die ein wichtiger Teil des sozialen Lebens ist und nun einzustürzen droht.<br/><br/>
               Der EPKT Dubasarii Vechi e.V. möchte die Kirchengemeinde bei der Sanierung der Kirche finanziell unterstützen.<br/><br/>
               Hintergrund: Die Republik Moldau, oder umgangssprachlich Moldawien, ist ein kleines Land mit rund 2,6 Mio.
               Einwohnern am westlichen Rand des schwarzen Meeres und wird im Nordosten von der Ukraine sowie im
               Südwesten von Rumänien eingerahmt. Damit liegt es nicht nur geografisch sondern auch historisch bedingt im
               Spannungsfeld politischer Interessen.<br/><br/>
               Im Gegensatz zu seinen Nachbarstaaten ist Moldawien vielen Menschen nicht bekannt und steht somit nicht im
               Fokus des öffentlichen, aber auch des individuellen Interesses. Das Land ist geprägt von seiner ereignisreichen
               Geschichte, aber auch von der aktuellen Situation, die unter anderem von der Auswanderung vieler junger
               qualifizierter Menschen geprägt ist.<br/><br/>
               Moldawien ist eines der ärmsten Länder Europas. Obwohl sich speziell in der Hauptstadt ein gewisser Wohlstand
               zeigt, ist der Mangel an finanziellen Mitteln und Unterstützung speziell im eher ländlichen Raum sichtbar und
               spürbar. Ein Trend, der nicht nur Furchen ins Stadtbild zieht, sondern auch sozial für die Menschen spürbar ist.<br/><br/>
               Unser konkretes Projekt: Die orthodoxe Kirche der Erzengel Michael Gemeinde von Dubasarii Vecchi wurde
               während des zweiten Weltkrieges stark beschädigt. Die Reparaturen nach dem Krieg haben bis heute notdürftig
               gehalten. Nun droht die Kuppel einzustürzen. Sanierungsarbeiten sind dringend notwendig und bereits initiiert, um
               die Kirche für die Bewohner von Dubasarii Vechii nutzbar zu erhalten.<br/><br/>
               Unser Ziel: Die Kosten zur Sanierung der Kirche sind auf rund 100.000 Euro geschätzt. Aktuell benötigt die Gemeinde
               rund 6.000 Euro für Architektenleistungen. Die Spenden aus diesem Aufruf kommen dem Zweck zu 100% zu Gute.<br/><br/>
               Was geschieht mit der Spende: Auf unserer Homepage können Sie sich über unseren Verein, Moldawien, Dubasarii
               Vechi und das Sanierungsprojekt informieren. Wir stehen in engem Kontakt mit dem Priester der Gemeinde aus
               Dubasarii Vechi. Auf unserer Homepage informieren wir regelmäßig über den Baufortschritt und den Stand der
               Dinge.<br/><br/>
               Gerne können Sie über unsere Info-Mail-Adresse Kontakt zu uns aufnehmen. Mit herzlichen Grüßen,
               Ihr EPKT-Dubasarii-Vechi.<br/><br/>
               Spendekonto: DE59 6006 2775 0017 1150 00, Volksbank Raiffeisenbank Echterdingen
            </p>
        
      </TabPanel>
      </div>
      <div className='projectTabTwo'>
      <TabPanel value={value} index={1}>
        <div className='projectTabTwo_direction'>
            <div className='projectTabTwo_direction_picture'>
              <img src={projectOne}/>
            </div>
            <div className='projectTabTwo_direction_text'>
              <h3>Die Geschichte der Kirche „St. Erzengel 
                  Michael aus dem Dorf Dubasarii Vechi
              </h3>
              <p>Die Ortschaft Dubăsarii Vechi wurde erstmals 1610 urkundlich erwähnt. 
                 Es gibt keine Informationen über die Existenz einer Kirche zu dieser Zeit. 
                 Archivdokumente erzählen uns von einer Kirche, die 1795 von den Einwohnern 
                 des Dorfes Dubăsarii Vechi gebaut wurde. Es war eine Holzkirche, aber mit 
                 einem Steinfundament. Am 10. Juli 1842 brach ein großes Feuer aus, das 
                 den größten Teil des Dorfes einschließlich der Kirche niederbrannte.
                 <a target="_blank" href='http://protopopiat-criuleni-dubasari.md/istoria-bisericii-cu-hramul-sf-arhanghel-mihail-din-s-dubasarii-vechi-r-l-criuleni/'> Weiter lesen...</a>
                 </p>
            </div>
        </div>
        <hr/>
        <div className='projectTabTwo_direction'>
            <div className='projectTabTwo_direction_picture'>
            <img src={projectTwo}/>
            </div>
            <div className='projectTabTwo_direction_text'>
              <h3>Kurze historische Daten über die Priester, 
                  aus dem Dorf Dubăsarii Vechi
              </h3>
              <p>Ab 1801 der Priester Grigorie Cerlat; ab 1802  Priester Calistrat Schidu;
                 ab 1837 Priester Nestor Cerlat; ab 1849 Priester Nicolae Schidu;
                 seit 1862 Priester Teodor Trifon; seit 1886 Priester Constantin Guma;
                 ab 1929 Priester Gheorghe Harega; Seit 1929 Priester Mihail Guma; 
                 Seit 1947 Priester Vitalie Verdeş; Seit 1955 Priester Pavel Dogot;
                 Priester Ion Matei Rotaru 1979-1992; Priester Vasile Radu 1992-1996; 
                 Priester Ion Ciobanu 1997-2004; Seit dem 20. September 2004 Priester Teodor Ioan Pelin.
                 <a target="_blank" href='http://protopopiat-criuleni-dubasari.md/scurte-date-istorice-despre-preotii-care-au-slujit-in-biserica-sf-arh-mihail-din-s-dubasarii-vechi-r-l-criuleni/'> Weiter lesen...</a>
              </p>
            </div>
        </div>
        <hr/>
        <div className='projectTabTwo_direction'>
            <div className='projectTabTwo_direction_picture'>
            <img src={projectThree}/>
            </div>
            <div className='projectTabTwo_direction_text'>
              <h3>Das Denkmal in Dubăsarii Vechi</h3>
              <p>Im Innenhof, wenige Meter östlich der Apsis des Altars der Kirche 
                 „St. Erzengel Michael “aus Dubăsarii Vechi, es gibt ein Grab der Bojarenfamilie 
                 Macri, das in der zweiten Hälfte des 19. Jahrhunderts erbaut wurde.
                 Eines der Werke von A. Bernardazzi ist das wenig bekannte Denkmal - das Grabmal 
                 von Dubăsarii Vechi, das in der Tradition des klassischen Baustils mit antiken 
                 Elementen ausgeführt wurde. Davon zeugt die eingravierte Inschrift: 
                 „Arch. A. Bernardazzi“.
                 <a target="_blank" href='http://protopopiat-criuleni-dubasari.md/monumentul-memorial-din-dubasarii-vechi/'> Weiter lesen...</a>
                 </p>
            </div>
        </div>
      </TabPanel>
      </div>
      <div className='projectTabThree'>
      <TabPanel value={value} index={2}>
      <div className='projectTabThree_galery'>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryOne}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/biserica-cu-hramul-sf-arhanghel-mihail-din-s-dubasarii-vechi-r-l-criuleni-galerie-foto/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryTwo}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/biserica-sf-arh-mihail-din-s-dubasarii-vechi-r-criuleni-si-a-sarbatorit-hramul/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryThree}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/sfanta-cuvioasa-maica-matrona-cu-evlavie-intampinata-in-protopopiatul-de-criuleni-si-dubasari/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryFour}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/ziua-mondiala-a-familiei-celebrata-in-s-dubasarii-vechi-raionul-criuleni/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryFive}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/concordia-pentru-parintii-nostri-asistenta-religioasa-in-s-dubasarii-vechi-raionul-criuleni/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGallerySix}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/sfintirea-unui-mic-zooparc-la-biserica-din-dubasarii-vechi-raionul-criuleni/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGallerySeven}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/sarbatoarea-nasterii-domnului-la-dubasarii-vechi-r-criuleni/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryEight}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/popas-aniversar-la-s-dubasarii-vechi-r-l-criuleni/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryNine}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/scoala-duminicala-din-satul-dubasarii-vechi-la-un-an-de-activitate/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryTen}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/vizita-de-lucru-a-ips-mitropolit-vladimir-in-s-dubasarii-vechi-raionul-criuleni/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryEleven}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/crucii-tale-ne-inchinam-stapane/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryTwelve}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/150-de-ani-de-la-tarnosirea-bisericii-sf-arhanghel-mihail-din-s-dubasarii-vechi-r-l-criuleni-galerie-foto/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryThirteen}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/un-nou-centru-social-la-dubasarii-vechi/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryFourteen}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/sfintirea-gradinitei-2007-galerie-foto/'>Weiter lesen...</a></p>
        </div>
        <div className='projectTabThree_galery_card'>
              <img src={fotoGalleryFifteen}/>
              <p><a target="_blank" href='http://protopopiat-criuleni-dubasari.md/sfintirea-lagarului-de-copii-2006-galerie-foto/'>Weiter lesen...</a></p>
        </div>    
      </div>
      </TabPanel>
      </div>     
    </Box>
  );
}


